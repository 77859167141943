'use client'

import styles from './NotFound.module.scss'
import classNames from 'classnames/bind'
import Button from '@/components/Links/Button/Button'

const classes = classNames.bind(styles)

// Next.js 13's not-found.js page isn't working, so we're using this instead.
const NotFound = ({}) => {
    return (
        <div className={classes('section')}>
            <div className={classes('wrap')}>
                <h1 style={{ marginBottom: 0 }}>Well, this is awkward.</h1>
                <p>
                    It seems the page you were looking for is out on an extended lunch break. We'll keep calling its
                    phone and hopefully it'll pick up soon.
                </p>
                <Button text="Return home" url="/" type="primary" style={{ margin: 'auto' }} />
            </div>
        </div>
    )
}
export default NotFound
